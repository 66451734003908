import router from "@/router";
import store from "@/store";
import { Message } from "element-ui";
import NProgress from "nprogress";
import { getToken } from "@/utils/auth";
import whiteList from "./whiteList";
/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    const roles = store.getters && store.getters.roles;
    if (roles.includes("admin")) return true;
    return roles.some((role) => {
      return value.includes(role);
    });
  } else {
    console.error("need roles! Like v-permission=\"['admin','editor']\"");
    return false;
  }
}

// permission judge function
function hasPermission(roles, permissionRoles) {
  if (roles.indexOf("admin") >= 0) return true; // admin permission passed directly
  if (!permissionRoles) return true;
  return roles.some((role) => permissionRoles.indexOf(role) >= 0);
}

// 路由守卫函数
export const guardFunc = async (to, from, next) => {
  NProgress.start(); // start progress bar
  !getToken() && (await store.dispatch("Login"));
  if (getToken()) {
    // determine if there has token
    /* has token*/
    if (store.getters.roles.length === 0) {
      // 判断当前用户是否已拉取完user_info信息
      NProgress.done();
      store
        .dispatch("GetInfo")
        .then((res) => {
          // 拉取user_info
          const roles = res.data.roles; // note: roles must be a array! such as: ['editor','develop']
          store.dispatch("GenerateRoutes", { roles }).then(() => {
            // 根据roles权限生成可访问的路由表
            console.log(store.getters.addRouters);
            let addRouters = store.getters.addRouters;
            addRouters.length && router.addRoutes(addRouters); // 动态添加可访问路由表

            next({ ...to, replace: true }); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
          });
        })
        .catch((err) => {
          Message.error(err || "验证失败, 请重新登录！");
          setTimeout(function () {
            store.dispatch("FedLogOut");
          }, 2000);
        });
    } else {
      next();
      NProgress.done();
      // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
      if (hasPermission(store.getters.roles, to.meta.roles)) {
        next();
      } else {
        next({ path: "/401", replace: true, query: { noGoBack: true } });
      }
    }
  } else {
    /* has no token*/
    if (whiteList.includes(to.path)) {
      // 在免登录白名单，直接进入
      next();
    } else {
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
      // store.dispatch("FedLogOut");
      Message.error("没有权限，请先登录！");
      setTimeout(function () {
        store.dispatch("FedLogOut");
      }, 2000);
    }
  }
};
