import Layout from "@/views/layout/Layout";

export var constantRouterMap = [
  {
    path: "/404",
    component: () => import("@/views/errorPage/404"),
    hidden: true,
  },
  {
    path: "/example",
    component: () => import("@/views/example/index"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/errorPage/401"),
    hidden: true,
  },
  {
    path: "/generator/:uuid",
    name: "Generator",
    component: () => import("@/views/generator"),
    hidden: true,
  },
  {
    path: "",
    component: Layout,
    redirect: "/dashboard",
    name: "Dashboard",
    children: [
      {
        path: "dashboard",
        meta: {
          title: "首页",
          icon: "",
        },
        component: () => import("@/views/dashboard/index"),
      },
    ],
  },
  {
    path: "/activity-list",
    component: Layout,
    name: "ActivityList",
    children: [
      {
        path: "activityList",
        meta: {
          title: "活动列表",
          icon: "",
          roles: [
            "zt.add_zt",
            "zt.view_zt",
            "zt.modify_zt",
            "zt.design_zt",
            "zt.shelf_zt",
            "zt.log_zt",
            "zt.view_ldd",
            "zt.log_zt",
          ],
        },
        component: () => import("@/views/activityList/index"),
      },
    ],
  },
  {
    path: "/luck-draw",
    component: Layout,
    name: "LuckDraw",
    children: [
      {
        path: "luckDraw",
        meta: {
          title: "抽奖列表",
          icon: "",
          roles: ["ld.add_ld", "ld.modify_ld", "ld.modify_zt", "ld.view_ld", "ld.manage_ldi"],
        },
        component: () => import("@/views/luckDraw/index"),
      },
    ],
  },
];

export var asyncRouterMap = [
  {
    path: "/cluesRuleConfiguration",
    component: Layout,
    redirect: "cluesRuleConfiguration/allocationRules",
    name: "cluesRuleConfiguration",
    meta: {
      title: "线索规则配置",
      roles: [],
    },
    alwaysShow: true,
    children: [
      {
        path: "allocationRules",
        meta: {
          title: "分配规则",
          icon: "",
          roles: ["rule.view_lead_assign_rule"],
        },
        component: () => import("@/views/allocationRules/index"),
      },
    ],
  },
];
