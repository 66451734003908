import { login, logout, getInfo } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { redirectToOA } from "@/utils";
import Cookies from "js-cookie";
const user = {
  state: {
    token: getToken(),
    name: "",
    userId: "",
    avatar: "",
    roles: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER_ID: (state, userId) => {
      state.userId = userId;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
  },

  actions: {
    // 登录
    Login({ commit }, data) {
      return new Promise((resolve, reject) => {
        const _login = data?._login ? data._login : Cookies.get("_login");
        if (!_login) redirectToOA();
        login({ _login })
          .then((response) => {
            const data = response.data;
            setToken(data.token);
            commit("SET_TOKEN", data.token);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token)
          .then((response) => {
            const data = response.data;
            // data.roles = data.roles.concat(['base_services.view_advertisement'])
            if (data.roles && data.roles.length > 0) {
              // 验证返回的roles是否是一个非空数组
              commit("SET_ROLES", data.roles);
            } else {
              reject("权限列表为空，请先分配权限!");
            }
            commit("SET_USER_ID", data.unionuuid);
            commit("SET_NAME", data.name);
            commit("SET_AVATAR", data.avatar);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            removeToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 登出 并重新登录
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        commit("SET_ROLES", []);
        removeToken();
        redirectToOA();
        resolve();
      });
    },
  },
};

export default user;
