<template>
  <div class="decorate">
    <div class="decorate-head">{{ title }}</div>
    <div class="decorate-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfigureDecorate",
  props: ["title"],
  data() {
    return {};
  },
  methods: {},
  components: {},
};
</script>

<style lang="less" scoped>
.decorate {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;

  //   background: #fff;
  background: #fefefe;

  .decorate-head {
    height: 70px;
    line-height: 70px;
    padding-left: 12px;
    border-bottom: 1px solid #f2f4f6;
    font-size: 18px;
    font-weight: 600;
    color: #323233;
    text-align: left;
  }

  .decorate-body {
    height: calc(100% - 110px);
    overflow: auto;
    // padding-top: 20px;
    border-bottom: 1px solid #ebedf0;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
