<template>
  <configure-item :label="label">
    <el-input-number v-model="mValue" class="ml60" :min="mOptions.min" :max="mOptions.max" />
  </configure-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaNumber",

  mixins: [schemaMixin],

  data() {
    return {
      defaultOptions: {
        max: 100,
      },
    };
  },
};
</script>
