const OSS = require("ali-oss");
import { aliSts } from "@/api/common";

// 在客户端使用临时访问凭证初始化OSS客户端，用于临时授权访问OSS资源。
async function clientInit() {
  // 设置客户端请求访问凭证的地址。
  const { data: token } = await aliSts();

  return new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
    region: "oss-cn-shanghai",
    accessKeyId: token.access_key_id,
    accessKeySecret: token.access_key_secret,
    stsToken: token.security_token,
    // 填写Bucket名称，例如examplebucket。
    bucket: token.bucket,
    // 刷新临时访问凭证的时间间隔，单位为毫秒。
    refreshSTSTokenInterval: token.expiration,
    // 刷新临时访问凭证。
    refreshSTSToken: async () => {
      const { data: refreshToken } = await aliSts();
      return {
        accessKeyId: refreshToken.access_key_id,
        accessKeySecret: refreshToken.access_key_secret,
        stsToken: refreshToken.security_token,
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        refreshSTSTokenInterval: refreshToken.expiration,
      };
    },
  });
}

export async function upload(file) {
  console.log(file);
  try {
    const client = await clientInit();
    // file表示浏览器中需要上传的文件，支持HTML5 file和Blob类型。
    const fileName = `${Date.now()}_${Math.random(0, 100)}_${file.name}`;
    const result = await client.put("optimus/" + fileName, file);
    console.log("put success: %j", result);
    // const r2 = await client.get("optimus/" + fileName);
    // console.log("get success: %j", r2);
    return result;
  } catch (e) {
    console.error("error: %j", e);
  }
}
