<template>
  <el-tooltip
    class="item"
    effect="light"
    :content="label"
    placement="bottom"
    :open-delay="200"
    :disabled="label == ''"
  >
    <div
      class="mode-select-item flex-center"
      :class="[value == mode.mValue ? 'mode-select-item-active' : '']"
      @click="clickItem"
    >
      <i v-if="icon" class="icon iconfont f20 f-bold" :class="icon"></i>
      <span v-else>{{ label }}</span>
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: "select-item",

  props: ["label", "value", "icon"],

  inject: ["mode"],

  methods: {
    clickItem() {
      // console.log("label111", this.label); //一左两右
      // console.log("value222", this.value); // capcub3
      console.log("mode333", this.mode); // {}

      this.mode.mValue = this.value;
      this.mode.mTooltip = this.label;
    },
  },
  created() {
    setTimeout(() => {
      console.log("mode22::: ", this.mode);
      // console.log("label:33 ", this.label);
      // console.log("value:33 ", this.value);
      // console.log("icon:44 ", this.icon);
    }, 100);
  },
};
</script>

<style lang="less" scoped>
.mode-select-item {
  padding: 8px 12px;
  min-width: 55px;
  border: 1px solid #dcdee0; /*no*/
  border-radius: 0;
  border-color: #ebedf0;
  font-size: 12px; /*no*/
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: @theme-color;
    border-color: @theme-color;
    background-color: #e0edff;
    i {
      color: @theme-color;
    }
  }

  i {
    color: #979797;
  }
}

.mode-select-item-active {
  color: @theme-color;
  border-color: @theme-color;
  background-color: #e0edff;
  i {
    color: @theme-color;
  }
}
</style>
