<template>
  <configure-item :label="label">
    <ImgpondMy v-model="mValue" v-bind="mOptions" :count="1" />
  </configure-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaUpload",

  mixins: [schemaMixin],
};
</script>
