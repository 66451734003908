import axios from "@/utils/request";

export function login(data) {
  return axios({
    url: "/admin_api/v1/account/auth/token/create",
    method: "post",
    data,
  });
}

export function getInfo() {
  return axios({
    url: "/admin_api/v1/account/auth/user/info",
    method: "get",
  });
}

export function logout() {
  return axios({
    url: "/admin_api/v1/account/auth/user/logout",
    method: "get",
  });
}

export function getApplication() {
  return axios.get("/admin_api/v1/account/user/application");
}
