import { Message } from "element-ui";

// 跳转到OA登录
export function redirectToOA() {
  const oa = process.env.VUE_APP_OA_URL;
  location.href = oa + "/login/?backurl=" + location.href;
}

// 返回使用终端
export function getplatforms(platforms) {
  let text = [];
  if (!platforms) return;
  platforms.forEach((item) => {
    let name = ["PC", "H5", "安卓", "IOS"][item];
    text.push(name);
  });
  return text.join();
}

// parse iso string
export function parseISOString(s) {
  var b = s.split(/\D+/);
  // console.log(b)
  return new Date(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]);
}

/**
 * 使用 toString 来获取准确的应用类型
 * @param {*} target
 * @return {*}
 */
export const getType = (target) => {
  return Object.prototype.toString.call(target);
};

export const mapTag = "[object Map]";
export const setTag = "[object Set]";
export const arrayTag = "[object Array]";
export const objectTag = "[object Object]";
export const nullTag = "[object Null]";
export const undefinedTag = "[object Undefined]";
export const boolTag = "[object Boolean]";
export const dateTag = "[object Date]";
export const errorTag = "[object Error]";
export const numberTag = "[object Number]";
export const regexpTag = "[object RegExp]";
export const stringTag = "[object String]";
export const symbolTag = "[object Symbol]";

/**
 * 判断是否为引用类型，主要考虑 function 和 null 两种特殊数据类型
 * @param {*} target
 * @return {*}
 */
export const isObject = (target) => {
  const type = typeof target;
  return target !== null && (type === "object" || type === "function");
};

/**
 * 一个空方法，返回入参本身或空对象
 */
export const noop = () => {};

// 复制内容到剪切班
export function copyText(text) {
  let oInput = document.createElement("input");
  // oInput.style.display = 'none'
  oInput.value = text;
  document.body.prepend(oInput); // appendChild追加会导致iPhone弹窗跳至页面最底端
  oInput.select(); // 选择对象;
  document.execCommand("Copy"); // 执行浏览器复制命令
  Message({
    type: "success",
    message: "复制成功!",
    duration: 1000,
  });
  oInput.remove();
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "undefined" || time === null || time === "null") {
    return "";
  } else if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

// 下载文件
export function downloadFile(obj, name, suffix) {
  const url = window.URL.createObjectURL(new Blob([obj]));
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  const fileName = parseTime(new Date()) + "-" + name + "." + suffix;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
