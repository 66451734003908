<template>
  <configure-item :label="label">
    <div class="wrap">
      <TqSwitch v-model="mValue" v-bind="options" />
      &nbsp;&nbsp;
    </div>
  </configure-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import TqSwitch from "@/components/TqSwitch";

export default {
  name: "SchemaSwitch",

  mixins: [schemaMixin],
  components: {
    TqSwitch,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px; /*no*/

  .wrap-label {
    font-size: 14px; /*no*/
    line-height: 32px; /*no*/
  }
}
</style>
