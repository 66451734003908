<template>
  <el-select :value="value" size="small" class="site-nav" @change="change">
    <el-option
      v-for="item in nav"
      :key="item.client_id"
      :label="item.name"
      :value="item.client_id"
    />
  </el-select>
</template>

<script>
import { getApplication } from "@/api/login";

export default {
  data() {
    return {
      nav: [],
      value: "",
    };
  },
  created() {
    getApplication().then((res) => {
      this.nav = res.data;
      const sys = res.data.find((item) => item.cur);
      if (sys) {
        this.value = sys.client_id;
      }
    });
  },
  methods: {
    change(val) {
      const sys = this.nav.find((item) => item.client_id === val);
      const url = "http://" + sys.get_host;
      if (sys.is_open_new_win) {
        window.open(url);
      } else {
        location.href = url;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.site-nav {
  width: 150px;
}
</style>
