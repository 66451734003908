<template>
  <div class="flex p10">
    <div class="w99 f13 f-grey pt10">{{ label }}</div>
    <div class="flex-center">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfigureItem",
  props: ["label"],
  data() {
    return {};
  },
  methods: {},
  components: {},
};
</script>

<style lang="less" scoped></style>
