const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar,
  userId: (state) => state.user.userId,
  name: (state) => state.user.name,
  roles: (state) => state.user.roles,
  permission_routers: (state) => state.permission.routers,
  addRouters: (state) => state.permission.addRouters,
  organization: (state) => state.user.organization,
  management: (state) => state.user.management,
};
export default getters;
