export let modelOptions = [
  { label: "填写收货信息", type: "receiveInfoPopup" },
  { label: "我的奖品弹框", type: "myPrizePopup" },
  { label: "文本弹框", type: "textPopup" },
  { label: "图文弹框", type: "imageTextPopup" },
  { label: "表单弹框", type: "formPopup" },
];

export let schemaOptions = {
  receiveInfoPopup: [
    {
      label: "背景图",
      type: "upload",
      fieldName: "backgroundImage",
      value: "",
    },
    {
      label: "显示按钮",
      type: "switch",
      fieldName: "showBtn",
      value: true,
    },
    {
      label: "按钮文案",
      type: "string",
      fieldName: "btnText",
      value: "按钮文案",
    },
    {
      label: "文案颜色",
      type: "color",
      fieldName: "btnTextColor",
      value: "#ffffff",
    },
    {
      label: "按钮颜色",
      type: "color",
      fieldName: "btnColor",
      value: "#494ff5",
    },
  ],
  myPrizePopup: [
    {
      label: "背景图",
      type: "upload",
      fieldName: "backgroundImage",
      value: "",
    },
  ],
  textPopup: [
    {
      label: "背景图",
      type: "upload",
      fieldName: "backgroundImage",
      value: "",
    },
    {
      label: "文字内容",
      type: "editor",
      fieldName: "textContent",
      value: "",
      options: {
        type: "textarea",
      },
    },
    {
      label: "显示按钮",
      type: "switch",
      fieldName: "showBtn",
      value: false,
    },
    {
      label: "按钮文案",
      type: "string",
      fieldName: "btnText",
      value: "按钮文案",
    },
    {
      label: "文案颜色",
      type: "color",
      fieldName: "btnTextColor",
      value: "#ffffff",
    },
    {
      label: "按钮颜色",
      type: "color",
      fieldName: "btnColor",
      value: "#494ff5",
    },
  ],
  imageTextPopup: [
    {
      label: "背景图",
      type: "upload",
      fieldName: "backgroundImage",
      value: "",
    },
    {
      label: "弹框标题",
      type: "string",
      fieldName: "title",
      value: "",
    },
    {
      label: "显示按钮",
      type: "switch",
      fieldName: "showBtn",
      value: false,
    },
    {
      label: "按钮文案",
      type: "string",
      fieldName: "btnText",
      value: "按钮文案",
    },
    {
      label: "文案颜色",
      type: "color",
      fieldName: "btnTextColor",
      value: "#ffffff",
    },
    {
      label: "按钮颜色",
      type: "color",
      fieldName: "btnColor",
      value: "#494ff5",
    },
  ],
  formPopup: [
    {
      label: "背景图",
      type: "upload",
      fieldName: "backgroundImage",
      value: "",
    },
    {
      label: "弹框标题",
      type: "string",
      fieldName: "title",
      value: "",
    },
    {
      label: "按钮文案",
      type: "string",
      fieldName: "btnText",
      value: "按钮文案",
    },
    {
      label: "按钮背景色",
      type: "color",
      fieldName: "btnBgColor",
      value: "#494FF5",
    },
    {
      label: "表单ID",
      type: "string",
      fieldName: "formId",
      value: "mMAfVDA9n",
    },
  ],
};
