import axios from "@/utils/request";

// 活动列表
export function getActivityList(params) {
  return axios.get("/admin_api/v1/activity", { params });
}

// 添加活动
export function addActivity(data) {
  return axios.post(`/admin_api/v1/activity`, data);
}

// 编辑活动
export function editActivity(id, data) {
  return axios.put(`/admin_api/v1/activity/${id}`, data);
}

// 活动详情
export function getActivityDetail(id) {
  return axios.get(`/admin_api/v1/activity/${id}`);
}

// 活动上下架
export function activityDisplay(id, data) {
  return axios.put(`/admin_api/v1/activity/${id}/display`, data);
}
// 活动删除 /admin_api/v1/activity/{uuid}
export function activityDelete(id) {
  return axios.delete(`/admin_api/v1/activity/${id}`);
}

// 日志列表
export function getOperationLog(id) {
  return axios.get(`/admin_api/v1/activity/${id}/log`);
}
