import axios from "@/utils/request";

/**
 * @description: 查看规则列表
 * @param {*} params {rule_type:规则类型}
 */
export function getRuleList(params) {
  return axios.get("/admin_api/v1/rule", { params });
}

/**
 * @description: 查看规则详情
 * @param {*} id
 */
export function getRuleDetail(id) {
  return axios.get(`/admin_api/v1/rule/${id}`);
}

/**
 * @description: 创建规则
 * @param {*} data
 */
export function createRule(data) {
  return axios.post("/admin_api/v1/rule", data);
}

/**
 * @description:修改规则
 * @param {*} data
 */
export function putRule(id, data) {
  return axios.put(`/admin_api/v1/rule/${id}`, data);
}

/**
 * @description:移动规则
 * @param {*} data {prev:前一个id,next:后一个id}
 */
export function moveRule(id, data) {
  return axios.put(`/admin_api/v1/rule/${id}/move`, data);
}

/**
 * @description:启用禁用规则
 * @param {*} data {is_enable:是否启用}
 */
export function enableRule(id, data) {
  return axios.put(`/admin_api/v1/rule/${id}/enable`, data);
}

/**
 * @description: 关系运算符
 */
export function relationalOperators() {
  return axios.get(`/admin_api/v1/rule/relation_type`);
}

/**
 * @description:筛选参数
 * @param {*} params {ruleType:规则类型}
 */
export function filterParameters(params) {
  return axios.get(`/admin_api/v1/rule/property`, { params });
}

/**
 * @description: 逻辑运算符
 */
export function logicalOperators() {
  return axios.get(`/admin_api/v1/rule/logic_type`);
}
