<template>
  <configure-item :label="label">
    <div class="flex row-right" style="width: 90px">
      <el-color-picker
        v-model="mValue"
        :predefine="predefineColors"
        v-bind="mOptions"
        show-alpha
      ></el-color-picker>
    </div>
  </configure-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaColor",

  mixins: [schemaMixin],

  components: {},

  data() {
    return {
      predefineColors: [
        "#ffffff",
        "#f5f5f5f5",
        "#F2F2F2",
        "#FF4444",
        "#FFCD00",
        "#3FBC87",
        "#5197FF",
        "#BADCFF",
        "#000000",
      ],
    };
  },
};
</script>
