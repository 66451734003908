<template>
  <div>
    <configure-item label="查看效果">
      <TqSwitch v-model="mValue.disabled" />
    </configure-item>
    <configure-item label="弹窗类型">
      <el-select
        v-model="mValue.currentShow"
        v-bind="mOptions"
        size="small"
        @change="handleChangePopupType"
      >
        <el-option
          v-for="(item, index) in modelOptions"
          :label="item.label"
          :value="item.key"
          :key="index"
        >
        </el-option>
      </el-select>
    </configure-item>

    <component
      v-for="(val, index) in schema"
      :key="index"
      :is="typeToComponent[val.type]"
      :options="val.options"
      :label="val.label"
      v-model="mValue.configs[currentType][val.fieldName]"
    >
    </component>
  </div>
</template>

<script>
import typeToComponent from "@/config/schema-template";

import schemaMixin from "@/mixin/schemaMixin";
import { modelOptions, schemaOptions } from "./config";
import TqSwitch from "@/components/TqSwitch";

export default {
  name: "SchemaPopup",
  mixins: [schemaMixin],
  components: {
    TqSwitch,
  },
  data() {
    return {
      schema: [],
      typeToComponent,
      schemaOptions,
      modelOptions,
      currentType: "",
      configs: {},
    };
  },
  mounted() {
    this.fetchData();
    this.initData();
  },
  methods: {
    initData() {
      // 初始化可待选弹框
      this.modelOptions = this.options.popupType.map((val) => {
        const res = this.modelOptions.find((el) => el.type === val.type);
        const tmp = JSON.parse(JSON.stringify(res));
        tmp.key = val.key;
        tmp.label = val.label;
        return tmp;
      });

      // 初始化弹窗配置项
      this.modelOptions.forEach((item) => {
        this.schemaOptions[item.type].forEach((e) => {
          if (!this.mValue.configs[item.key]) {
            this.$set(this.mValue.configs, item.key, {});
          }
          if (!this.mValue.configs[item.key][e.fieldName]) {
            this.$set(this.mValue.configs[item.key], e.fieldName, e.value);
          }
          if (!this.mValue.configs[item.key]["type"]) {
            this.$set(this.mValue.configs[item.key], "type", item.type);
          }
        });
        // 数据回显控制
        this.handleChangePopupType(this.mValue.currentShow);
      });
    },
    fetchData() {
      console.log("--- SchemaPopup fetchData mValue ---", this.mValue);
    },
    handleChangePopupType(v) {
      if (v) {
        this.currentType = v;
        const resp = this.modelOptions.find((val) => val.key === v);
        this.schema = this.schemaOptions[resp.type];
      } else {
        this.schema = [];
      }
    },
  },
};
</script>
