export let initialModels = {
  capcub0: [
    {
      top: 0,
      left: 0,
      bottom: 3,
      right: 3,
      height: 3,
      width: 6,
      adWidth: 331,
      adMinHeight: 94,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
  ],
  capcub1: [
    {
      top: 0,
      left: 0,
      bottom: 3,
      right: 3,
      height: 3,
      width: 3,
      adWidth: 162,
      adMinHeight: 93,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
    {
      top: 0,
      left: 3,
      bottom: 3,
      right: 6,
      height: 3,
      width: 3,
      adWidth: 162,
      adMinHeight: 93,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
  ],
  capcub2: [
    {
      top: 0,
      left: 0,
      bottom: 2,
      right: 2,
      height: 2,
      width: 2,
      adWidth: 105,
      adMinHeight: 99,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
    {
      top: 0,
      left: 2,
      bottom: 2,
      right: 4,
      height: 2,
      width: 2,
      adWidth: 105,
      adMinHeight: 99,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
    {
      top: 0,
      left: 4,
      bottom: 2,
      right: 6,
      height: 2,
      width: 2,
      adWidth: 105,
      adMinHeight: 99,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
  ],
  capcub3: [
    {
      top: 0,
      left: 0,
      bottom: 6,
      right: 3,
      height: 6,
      width: 3,
      adWidth: 166,
      adMinHeight: 207,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
    {
      top: 0,
      left: 3,
      bottom: 3,
      right: 6,
      height: 3,
      width: 3,
      adWidth: 158,
      adMinHeight: 100,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },

    {
      top: 3,
      left: 3,
      bottom: 6,
      right: 6,
      height: 3,
      width: 3,
      adWidth: 158,
      adMinHeight: 100,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
  ],
  capcub4: [
    {
      top: 0,
      left: 0,
      bottom: 3,
      right: 3,
      height: 3,
      width: 3,
      adWidth: 158,
      adMinHeight: 100,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
    {
      top: 3,
      left: 0,
      bottom: 6,
      right: 3,
      height: 3,
      width: 3,
      adWidth: 158,
      adMinHeight: 100,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
    {
      top: 0,
      left: 3,
      bottom: 6,
      right: 3,
      height: 6,
      width: 3,
      adWidth: 166,
      adMinHeight: 207,
      jumpData: {
        type: 1,
        value: "",
      },
      image: "",
    },
  ],
};

export let modelOptions = [
  {
    label: "一行一个",
    value: "capcub0",
    icon: "icon-yihangyige",
    row: 3,
  },
  {
    label: "一行两个",
    value: "capcub1",
    icon: "icon-yihangliangge",
    row: 3,
  },
  {
    label: "一行三个",
    value: "capcub2",
    icon: "icon-yihangsange",
    row: 2,
  },
  {
    label: "一左两右",
    value: "capcub3",
    icon: "icon-yizuoliangyou",
    row: 6,
  },
  {
    label: "两左一右",
    value: "capcub4",
    icon: "icon-liangzuoyiyou",
    row: 6,
  },
];
