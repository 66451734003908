<template>
  <configure-item :label="label">
    <div style="width: 340px; border: 1px solid #ccc">
      <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
      />
      <Editor
        style="height: 200px; overflow-y: hidden"
        v-model="mValue"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
      />
    </div>
  </configure-item>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { DomEditor } from "@wangeditor/editor";
import schemaMixin from "@/mixin/schemaMixin";
export default {
  name: "SchemaEditor",
  mixins: [schemaMixin],
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      toolbarConfig: {
        toolbarKeys: [
          "headerSelect",
          "|",
          "blockquote",
          "bold",
          "italic",
          "underline",
          "color",
          "bgColor",
          "fontSize",
          "|",
          "divider",
          {
            key: "group-justify",
            title: "对齐",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            menuKeys: ["justifyLeft", "justifyRight", "justifyCenter", "justifyJustify"],
          },
        ],
      },
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default", // or 'simple'
    };
  },
  mounted() {},
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      this.$nextTick(() => {
        const toolbar = DomEditor.getToolbar(editor);
        const curToolbarConfig = toolbar.getConfig();
        console.log("curToolbarConfig", curToolbarConfig.toolbarKeys);
      });
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
