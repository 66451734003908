<template>
  <div class="ctn">
    <div v-show="head" class="ctn-head"></div>
    <div class="ctn-body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhoneCtn",
  props: {
    head: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.ctn {
  position: relative;
  width: 375px;
  margin: 0 auto;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);

  .ctn-head {
    widows: 100%;
    height: 64px;
    background-image: url("./phone-head.png");
    background-size: cover;
  }

  .ctn-body {
    min-height: 603px;
    background: #fff;
  }

  .ctn-height-tag {
    position: absolute;
    top: 650px;
    left: -130px;
    height: 17px;
    width: 130px;
    border-bottom: 1px solid #dedede;
    color: #a2a2a2;
    text-align: left;

    span {
      font-size: 12px;
    }
  }
}
</style>
