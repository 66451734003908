<template>
  <configure-item :label="label">
    <el-select v-model="mValue" v-bind="mOptions" disabled size="small">
      <el-option
        v-for="item in freshPrize"
        :key="item.uuid"
        :label="item.name"
        :value="item.uuid"
      ></el-option>
    </el-select>
  </configure-item>
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";
import { getLuckDrawFresh } from "@/api/luckDraw";
import { getActivityDetail } from "@/api/activity";

export default {
  name: "SchemaPrize",
  mixins: [schemaMixin],
  data() {
    return {
      freshPrize: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const activityInfo = await getActivityDetail(this.$route.params.uuid);
      if (activityInfo.data.lucky_draw) {
        this.mValue = activityInfo.data.lucky_draw_uuid;
        const freshResp = await getLuckDrawFresh({
          is_used: true,
          is_active: true,
        });
        this.freshPrize = freshResp.data;
      }
    },
  },
};
</script>
