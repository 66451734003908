import axios from "@/utils/request";

// 获取抽奖配置列表
export function getLuckDrawList(params) {
  return axios.get(`/admin_api/v1/lucky_draw`, { params });
}

// 获取抽奖规则配置
export function getPrizeRule(uuid) {
  return axios.get(`/admin_api/v1/lucky_draw/${uuid}/rule`);
}

// 保存抽奖规则配置
export function savePrizeRule(data) {
  return axios.post(`/admin_api/v1/lucky_draw/rule`, data);
}

// 保存抽奖规则配置
export function updatePrizeRule(uuid, data) {
  return axios.put(`/admin_api/v1/lucky_draw/${uuid}/rule`, data);
}

// 获取奖项配置
export function getPrizeConfig(uuid) {
  return axios.get(`/admin_api/v1/lucky_draw/${uuid}/prize`);
}

// 保存奖项配置
export function savePrizeConfig(uuid, data) {
  return axios.post(`/admin_api/v1/lucky_draw/${uuid}/prize`, data);
}

// 修改奖项配置
export function updatePrizeConfig(l_uuid, i_uuid, data) {
  return axios.put(`/admin_api/v1/lucky_draw/${l_uuid}/prize/${i_uuid}`, data);
}

// 修改奖项配置
export function updateEffectiveState(uuid) {
  return axios.put(`/admin_api/v1/lucky_draw/${uuid}/effective`);
}

// 删除奖项配置
export function deletePrizeConfig(l_uuid, i_uuid) {
  return axios.delete(`/admin_api/v1/lucky_draw/${l_uuid}/prize/${i_uuid}`);
}

// 复制奖项配置
export function copyPrizeConfig(uuid) {
  return axios.post(`/admin_api/v1/lucky_draw/${uuid}/rule/copy`);
}

// 获取抽奖统计--奖品信息
export function getStatisticalPrizeInfo(uuid) {
  return axios.get(`/admin_api/v1/lucky_draw/${uuid}/statistical/prize_info`);
}

// 获取抽奖统计--参与用户
export function getStatisticalParticipateUser(uuid, params) {
  return axios.get(`/admin_api/v1/lucky_draw/${uuid}/statistical/participate_user`, { params });
}

// 获取抽奖统计--中奖用户
export function getStatisticalWinUser(uuid, params) {
  return axios.get(`/admin_api/v1/lucky_draw/${uuid}/statistical/win_user`, { params });
}

// 获取可用抽奖配置
export function getLuckDrawFresh(params) {
  return axios.get(`/admin_api/v1/lucky_draw/fresh`, { params });
}

// 参与用户-导出
export function exportParticipateUser(uuid, params) {
  return axios.get(`/admin_api/v1/lucky_draw/${uuid}/statistical/participate_user/export`, {
    params,
    responseType: "blob",
  });
}
// 中奖用户-导出
export function exportWinUser(uuid, params) {
  return axios.get(`/admin_api/v1/lucky_draw/${uuid}/statistical/win_user/export`, {
    params,
    responseType: "blob",
  });
}
