<template>
  <div>
    <el-collapse v-model="activeNames">
      <el-collapse-item class="wrap-title" name="1">
        <template slot="title"> <i class="cus-header-icon"></i> {{ title }}</template>
        <div class="wrap-body">
          <slot></slot>

          <div v-show="line" class="wrap-line"></div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "ConfigureWarp",
  props: {
    title: {
      type: String,
      default: "",
    },
    line: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeNames: ["1"],
    };
  },
  methods: {},
  components: {},
};
</script>

<style lang="less" scoped>
.wrap-title {
  ::v-deep .el-collapse-item__header {
    .cus-header-icon::before {
      color: #409eff;
      content: ".";
      width: 3px;
      height: 10px;
      margin: 0 8px;
      background: #409eff;
    }
  }
}

.wrap-body {
  padding: 20px 0 10px 20px;

  .wrap-line {
    margin-top: 30px;
    height: 1px;
    background: #ebedf0;
  }
}
</style>
