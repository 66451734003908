<template>
  <el-switch
    v-model="value"
    disabled
    :class="switchClass"
    :active-value="true"
    :inactive-value="false"
    active-color="#13ce66"
    inactive-color="#ff4949"
    active-text="是"
    inactive-text="否"
    @click.native="switchHandle()"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    confirm: {
      // 是否需要弹出确认提醒
      type: Boolean,
      default: false,
    },
    tip: {
      // 确认提醒内容
      type: String,
      default: "",
    },
    disabled: {
      // 是否响应操作
      type: Boolean,
      default: false,
    },
  },
  computed: {
    switchClass() {
      return this.disabled ? "custom-switch-view" : "custom-switch";
    },
  },
  methods: {
    switchHandle() {
      if (!this.disabled) {
        if (this.confirm) {
          this.$confirm(this.tip, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$emit("input", !this.value);
              this.$emit("change", !this.value);
            })
            .catch(() => {});
        } else {
          this.$emit("input", !this.value);
          this.$emit("change", !this.value);
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.custom-switch {
  ::v-deep .el-switch__label {
    cursor: pointer;
    position: absolute;
    display: none;
    color: #fff;
  }
  ::v-deep .el-switch__label--right {
    z-index: 1;
    right: 27px;
  }
  ::v-deep .el-switch__label--left {
    z-index: 1;
    left: 27px;
  }
  ::v-deep .el-switch__label.is-active {
    display: block;
  }
  ::v-deep .el-switch__core,
  .el-switch__label {
    cursor: pointer;
    width: 60px !important;
  }
}
.custom-switch-view {
  ::v-deep .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  ::v-deep .el-switch__label--right {
    z-index: 1;
    right: 27px;
  }
  ::v-deep .el-switch__label--left {
    z-index: 1;
    left: 27px;
  }
  ::v-deep .el-switch__label.is-active {
    display: block;
  }
  ::v-deep .el-switch__core,
  .el-switch__label {
    width: 60px !important;
  }
}
</style>
