import { settingProject, getProject, removeProject } from "@/utils/project";
export default {
  // namespaced: true,
  state: {
    project: getProject(),
    curComponent: null, // 当前物料
    dragComponent: null, // 拖拽物料
    dragStatus: false, // 拖拽入页面状态
  },
  getters: {
    project: (state) => state.project,
    curComponent: (state) => state.curComponent,
    dragComponent: (state) => state.dragComponent,
    dragStatus: (state) => state.dragStatus,
    pageConfig: (state) => state.project.config,
  },
  mutations: {
    // 赋值项目
    setProject(state, project) {
      state.project = project;
      settingProject(state.project);
    },
    // 初始化重置项目
    setDefaultProject(state) {
      removeProject();
      state.project = getProject();
      settingProject(state.project);
    },

    // 删除项目
    dropProject(state) {
      state.project = {};
      state.curComponent = null;
      state.dragComponent = null;
      state.dragStatus = false;
      removeProject();
    },

    // 当前操作操作物料
    setcurComponent(state, component) {
      state.curComponent = component;
    },

    // 当前拖拽物料
    setDragComponent(state, component) {
      state.dragComponent = component;
    },

    // 添加物料
    addComponentList(state, component) {
      state.curPage.componentList.push(component);
    },

    // 删除物料
    delComponent(state, id) {
      // 查找物料对应下标
      let index = state.project.componentList.reduce((pre, cur, i) => {
        if (cur.id == id) pre = i;
        return pre;
      }, -1);

      state.project.componentList.splice(index, 1);
      state.curComponent = null;
    },

    setDragStatus(state, status) {
      state.dragStatus = status;
    },
  },
};
