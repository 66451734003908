<template>
  <el-menu class="navbar" mode="horizontal">
    <hamburger
      :toggle-click="toggleSideBar"
      :is-active="sidebar.opened"
      class="hamburger-container"
    />
    <!-- <breadcrumb></breadcrumb> -->
    <site-nav />
    <div class="avatar-container">
      <router-link class="msg" to="/msg/index">
        <el-badge :value="unread">
          <i class="el-icon-bell"></i>
        </el-badge>
        消息中心
      </router-link>
      <el-dropdown trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar" />
          <span>欢迎你，{{ name }}</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link class="inlineBlock" to="/">
            <el-dropdown-item>首页</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided>
            <span style="display: block" @click="logout">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";
// import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from "@/components/Hamburger";
import SiteNav from "@/components/SiteNav";
import { redirectToOA } from "@/utils";

export default {
  data() {
    return {
      unread: 0,
    };
  },
  components: {
    // Breadcrumb,
    Hamburger,
    SiteNav,
  },
  computed: {
    ...mapGetters(["sidebar", "avatar", "name"]),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("ToggleSideBar");
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        redirectToOA();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.navbar {
  height: 63px;
  line-height: 63px;
  border-bottom: none !important;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.05);
  border-radius: 0px !important;
  .hamburger-container {
    height: 63px;
    line-height: 70px;
    float: left;
    padding: 0 30px;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 63px;
    display: inline-block;
    position: absolute;
    right: 35px;
    .msg {
      display: inline-block;
      vertical-align: top;
      padding-right: 10px;
      .el-badge {
        cursor: pointer;
        line-height: initial;
        i {
          font-size: 30px;
          vertical-align: middle;
        }
      }
    }
    .avatar-wrapper {
      cursor: pointer;
      display: flex;
      align-items: center;
      .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      span {
        color: #252b33;
        margin: 0 10px;
      }
    }
  }
}
</style>
